<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">BDO Market Status</h1>

        <p class="subheading font-weight-regular">
          To learn how to import and work with the sheets
          <br />
          check out
          <a href="http://bit.ly/MarketAPIGuide" target="_blank"
            >Summers Guide</a
          >
        </p>
      </v-col>
      <v-col class="mb-5" cols="12">
        <h2 class="display-1 font-weight-bold mb-3">Sheets Overview</h2>
        <v-row justify="center">
          <table>
            <tr>
              <th>region/lang</th>
              <th v-for="(lang, i) in languages" :key="i">
                {{ lang }}
              </th>
            </tr>

            <tr v-for="(row, i) in tableArray" :key="i">
              <td>{{ regions[i] }}</td>
              <td v-for="(file, i) in row" :key="i">
                <div class="pa-2">{{ file.icon }}</div>
              </td>
            </tr>
          </table>
        </v-row>
        <br />
        <p class="subheading font-weight-regular">
          <a href="https://discord.gg/4xyeQzV" target="_blank"
            >Join the Lifeskill Discord</a
          >
          <br />
          for help and discussion with other developers :)
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SheetsStatus",
  data() {
    return {
      files: [
        {
          kind: "drive#file",
          id: "1yHXcMWUC-797E50Lob-VUsyBNusC9ccbxzgt88qJLDs",
          name: "JP-jp",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1q50gm4O8Vx6qfXEOmZ7xOgSZ_h70oExmtagTZM1TvUk",
          name: "EU-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1VKMOsY2N2MYba-3ufidw5lPlbGb_1mXszA0KZ09nM9s",
          name: "CEU-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1ox0o-r9Ybff6UZk_4N4-Lh23m-NQvZ3aKvGCBWWbS8o",
          name: "RU-ru",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1MImPpNxX47JfblbJVWatItMNYK0U0QtPM-g-Xl3z2gA",
          name: "SEA-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1EFP3DLWPq76bVD6Z9z8-yoyjtJSlpOwbd7XHPd3YgqM",
          name: "MENA-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1j1fIyV_Gzn6iryAVzKwobk5pm8eLGBy8BGvZro6ja6Y",
          name: "EU-de",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "14vHOAsIj2kQfcsPsfQDYVspp38KFP_2dwFjrrapqQrU",
          name: "TH-th",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1hMJOyddJKVGbyBjUUwGWWGwWp-0hn452p6eRgLRDTj4",
          name: "EU-es",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1LqIi0RAm5pAtF66HTSNhTp5LRX5bPo_cc2sKarzg9Vw",
          name: "EU-fr",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1dBwNNUa4hA8aFNNb5DG3FKUGn4heiVg8C-98A5lFbWs",
          name: "CEU-de",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1PrbdGNuP812PZQaEW6seFvygzzI3zO5PlnNvJemqg2s",
          name: "TH-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1N4x4hfihUSoe9ETA8fn0dISRSyf2PQvDYtxEtv3F7Qg",
          name: "KR-kr",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1O7WfmIKezbSrIyWYewiqM_WnhrzGZlT_Z0lnLbY0so8",
          name: "NA-de",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1imwi55_O92okrpnkdlrThVuHa4J_Kth0kepc6v3O8iA",
          name: "MENA-tr",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1XH4oRQeTtecHBGYd87RmgMCZUZhCoq3AdC20akEgf38",
          name: "NA-es",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1BqU9WpfmensP8fgKV4Ji-a7cZAO8pcVhfsQqkysEUms",
          name: "SA-es",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1M256U1m3u3c6JC6iVtbpkdS-j4Dqj17U_uGBxc0I4TI",
          name: "JP-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1p9SiQEGn8fuK7314ujdYasejjT4ponxgsUdf9SgYmdg",
          name: "SA-pt",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1SACVeQqcIecs4kPwPhd2x0nJy54BCAprf2YA_xnaLgA",
          name: "TW-tw",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1hBBWZkRRPdGiUWZWp1hSTPnH2xl37G39COW3lUekW2k",
          name: "SA-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1QzB2AvZQXXkgc1CInu07XFxCx0oHBUFkFhEunYBvcEw",
          name: "CNA-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "18W68acOUsF7R09152mpJtqdnZSVhtj83k1v84t1tF0A",
          name: "RU-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1tPVsHE3mBawHp3tlzbj82nUq00gPto9Selc_Z3pJlY8",
          name: "TW-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1NsGi5c648KgnCyLdYWvtfkr36zjXK6FdBFxMjVQ_-9I",
          name: "NA-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        {
          kind: "drive#file",
          id: "1wLh6u4NwKXn_RZjoNdpKnGDrzh0fOgWJbojmLFVCny0",
          name: "KR-us",
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
      ],
      languages: [
        "us",
        "de",
        "fr",
        "ru",
        "es",
        "pt",
        "jp",
        "kr",
        "cn",
        "tw",
        "th",
        "tr",
        "id",
      ],
      regions: [
        "MENA",
        "RU",
        "EU",
        "NA",
        "TH",
        "JP",
        "SA",
        "SEA",
        "KR",
        "TW",
        "CEU",
        "CNA",
      ],
    };
  },
  computed: {
    tableArray() {
      let array = [];
      for (const region in this.regions) {
        let langArray = [];
        for (const lang in this.languages) {
          const entry = this.files.find(
            (f) => f.name === `${this.regions[region]}-${this.languages[lang]}`
          );
          if (entry) {
            langArray[lang] = { icon: "✓" };
          } else {
            langArray[lang] = { icon: "-" };
          }
        }
        array.push(langArray);
      }
      return array;
    },
  },
};
</script>
